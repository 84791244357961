import React, { useMemo, useState, useCallback, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  Card,
  Input,
  Table,
  AutoComplete,
  Select,
  Tag,
} from "antd";
import {
  useSearchCPT,
  useSearchDiagnosis,
  useSearchProvider,
  useSearchServices,
} from "../../../../../../API/apiUtils";
import {  useParams } from "react-router-dom";
import FacilityCode from "../../../../Setting/components/Datasets/FacilityCode";
import { DeleteOutlined } from "@ant-design/icons";
import {  doPost } from "../../../../../../API/apis";
import { useStateGlobalContext } from "../../../../../../contexts/GlobalContext";
import dayjs from "dayjs";
import Notification from "../../../../../../components/Notification/Notification";
import FormatDate from "../../../../../../components/FormatDate/FormatDate";
import { validateDecimalNumber, validateNumber, validatePrice } from "../../../../../../utils/validator";

const { Option } = Select;

const rules = {
  required: true,
  message: "This Field is Requried",
};

const SuperbillDrawer = ({ open, onClose, clientDetails }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm();
  const [diagnosisDetails, setDiagnosisDetails] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedCPT, setSelectedCPT] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState(FacilityCode);
  const [providerDetails, setProviderDetails] = useState(null)
  const [modifiers, setModifiers] = useState([]);
  const {
    officeSetting,
    getOfficeSettings,
    billingInformation,
    getBillingInformation,
    getSuperbill
  } = useStateGlobalContext();



  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([
          getBillingInformation(),
          getOfficeSettings()
        ]);
      } catch (error) {
        Notification.error('Something Went Wrong');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  const handleCancel = ()=>{
    onClose()
    form.resetFields()
    setDiagnosisDetails(null)
    setServices(null)
  }

  const {
    facilty_registration_number,
    tax_id,
    facilty_code,
    additional_billing_data_1,
    additional_billing_data_2,
    additional_billing_data_3
  }= billingInformation || {}

  const {
    facility_name,
    facility_address,
    office_phone, 
    office_fax
  } = officeSetting || {}


  
  const practise_info = [
    facility_name ? facility_name[1] : '',
    facilty_registration_number ? facilty_registration_number[0] : '',
    tax_id ? tax_id[1] : '',
    facility_address ? facility_address[0] : '',
    office_phone ? office_phone[0] : '',
    office_fax ? office_fax[0] : '',
    additional_billing_data_1 ? additional_billing_data_1[0] : '',
    additional_billing_data_2 ? additional_billing_data_2[0] : '',
    additional_billing_data_3 ? additional_billing_data_3[0] : ''
  ];
  

  

  const [selectedDiagnosisIds, setSelectedDiagnosisIds] = useState([]);
  const { diagnosisOptions: options, handleSearchDiagnosis } =
    useSearchDiagnosis();
  const { CPTCodesOptions, handleSearchCPTCodes } = useSearchCPT();
  const {provderInfo, handleSearchProviderInfo} = useSearchProvider()


  const { options: serviceTypeOptions, handleSearchServices } =
    useSearchServices();


    const handleSearchFacilityCodes= useCallback((searchText) => {
      const filteredOptions = FacilityCode.filter(
        (option) =>
          option?.service_name
            ?.toLowerCase()
            .includes(searchText.toLowerCase()) ||
          option?.description?.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredOptions(filteredOptions);
    }, []);

  const memoizedFilteredOptions = useMemo(
    () => filteredOptions,
    [filteredOptions]
  );

  const handleSelectDX = (selectedIds) => {
    setSelectedDiagnosisIds(selectedIds);
  };

  const generateLetter = (index) => {
    return String.fromCharCode(65 + index);
  };

  const handleChange = (value) => {
    if (value.length > 4) {
      Notification.error("You can only select up to 4 modifiers.");

      value.pop();
      return; 
    }
    setModifiers(value); 
  };

  const diagnosisColumn = [
    {
      key: 1,
      title: "#",
      align: "center",
      dataIndex: "dx_link",
    },
    {
      key: 2,
      title: "DX",
      align: "center",
      dataIndex: "dx",
    },
    {
      key: 3,
      title: "Diagnosis Description",
      dataIndex: "diagnosis_code",
    },
    {
      key: 4,
      title: "Action",
      align: "center",
      render: (_, __, index) => (
        <span>
          <DeleteOutlined
            style={{ color: "#F44336" }}
            onClick={() => handleDeleteDiagnosis(index)}
          />
        </span>
      ),
    },
  ];

  const servicesColumn = [
    {
      key: 1,
      title: "#",
      align: "center",
      render: (_, __, index) => index + 1,
    },
    {
      key: 2,
      title: "Date",
      align: "center",
      render: () => dayjs().format('MM/DD/YYYY'), 

    },
    {
      key: 3,
      title: "POS",
      dataIndex: "pos",
    },
    {
      key: 4,
      title: "Code",
      align: "center",
      dataIndex: "code",
    },
    {
      key: 5,
      title: "Service",
      align: "center",
      dataIndex: "service",
    },
    {
      key: 6,
      title: "DX",
      dataIndex: "dx",
      render: (dx) => (
        <>
          {dx.map((id, index) => (
            <Tag key={index}>{id}</Tag>
          ))}
        </>
      ),
    },

    {
      key: 7,
      title: "Modifier",
      align: "center",
      dataIndex: "modifier",
      render: (modifier) => (
        <>{modifier && modifier?.map((tag) => <Tag key={tag}>{tag}</Tag>)}</>
      ),
    },
    {
      key: 8,
      title: "Unit",
      align: "center",
      dataIndex: "unit",
    },
    {
      key: 9,
      title: "Fee",
      dataIndex: "fee",
    },
    {
      key: 10,
      title: "Paid",
      align: "center",
      dataIndex: "paid",
    },
    {
      key: 11,
      title: "Action",
      align: "center",
      render: (_, __, index) => (
        <span>
          <DeleteOutlined
            style={{ color: "#F44336" }}
            onClick={() => handleDeleteServices(index)}
          />
        </span>
      ),
    },
  ];

  const onSelectProvider = (value, option)=>{
    const selectedProvider =  provderInfo && provderInfo?.find((item) => item.value === value);
    if(selectedProvider){
      const provider_details = [
        {
            provider_name: value,
            npi: selectedProvider?.npi,
            tax_id: selectedProvider?.tax_id,
            phone: selectedProvider?.phone,
            additional_data_1: selectedProvider?.additional_data_1,
            additional_data_2: selectedProvider?.additional_data_2,
            additional_data_3: selectedProvider?.additional_data_3,
        }
      ]
      setProviderDetails(provider_details)
    }
  }

  const onSelect = (value, option) => {
    const selectedDiagnosis = options.find((item) => item.value === value);
    if (selectedDiagnosis) {
      const newDiagnosis = {
        dx_link: generateLetter(diagnosisDetails?.length),
        dx: selectedDiagnosis.value,
        diagnosis_code: selectedDiagnosis.description,
      };
      // Check if diagnosisDetails is iterable
      if (diagnosisDetails && typeof diagnosisDetails[Symbol.iterator] === 'function') {
        setDiagnosisDetails([...diagnosisDetails, newDiagnosis]);
      } else {
        setDiagnosisDetails([newDiagnosis]);
      }
    }
    form.setFieldsValue({ diagnosisCode: undefined });
  };
  



  const handleDeleteDiagnosis = (index) => {
    setDiagnosisDetails(diagnosisDetails.filter((_, i) => i !== index));
  };

  const handleDeleteServices = (index)=>{
    setServices(services?.filter((_, i) => i !== index))
  }

  const handleCPTCode = (value, option) => {
    const selectedCPTCode = CPTCodesOptions.find(
      (item) => item.value === value
    );
    if (selectedCPTCode) {
      setSelectedCPT({
        id: selectedCPTCode?.id,
        cptCode: selectedCPTCode?.value,
        serviceDescription: selectedCPTCode?.service_description,
        fee: selectedCPTCode?.fee,
      });
    }
  };

  const handleAddService = () => {
    form
      .validateFields(['pos', 'procedureCodes', 'dx_value', 'service_type', 'unit', 'paid'])
      .then(() => {
        setServices([
          ...services,
          {
            date: dayjs(new Date()).format('YYYY-MM-DD'),
            code: selectedCPT?.cptCode,
            serviceDescription: selectedCPT?.serviceDescription,
            fee: selectedCPT?.fee,
            paid: form.getFieldValue('paid'),
            service: form.getFieldValue('service_type'),
            unit: form.getFieldValue('unit'),
            modifier: form.getFieldValue('modifier'),
            dx: selectedDiagnosisIds,
            pos: form.getFieldValue('pos'),
          },
        ]);
        // Reset field values after adding the service
        form.resetFields([
          'procedureCodes',
          'dx_value',
          'service_type',
          'unit',
          'paid',
          'modifier'
        ]);
      })
      .catch((error) => {
        Notification.error('Please fill in all required fields.');
      });
  }; 
  


  const handleSuperbill = async (values) => {
    setLoading(true)
    const data = {
      prior_auth_number:  form.getFieldValue("prior_auth_number"),
      // office_name: "Wellspring",
      provider_info: providerDetails && providerDetails,
      office_info: form.getFieldValue('office_info').replace(/\n/g, ', '),
      diagnosis_code: diagnosisDetails,
      details: services,
    };
    try {
      const response = await doPost(`/superbill/create-bill/${id}/`, data);
      if (response.status === 201) {
        Notification.success("Superbill Created Successfully");
        handleCancel()
        getSuperbill(id)
      }
    } catch (error) {
      Notification.error(error);
    } finally{
      setLoading(false)
    }
  };


  return (
    <Modal
      open={open}
      title="Create Superbill"
      footer={null}
      width={950}
      onCancel={onClose}
      maskClosable={false}
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">
          Close
        </Button>
      }
    >
      <Form form={form} layout="vertical" >
        <Row gutter={[16, 16]}>
          <Col span={12}>

            <Form.Item label="Practice Information"
            name={'office_info'}
            initialValue={practise_info?.join("\n")}

            >
              <Input.TextArea placeholder="Practice Information" rows={5}  />
            </Form.Item>
          </Col>
          <Col span={12}>
          <Form.Item label="Provider Information" name={'provider-info'}>
            <AutoComplete
                name="diagnosisCode"
                onSearch={handleSearchProviderInfo}
                options={provderInfo}
                allowClear
                onSelect={onSelectProvider}
                placeholder="Search Provider"
                maxLength={100}
                style={{ minWidth: "400px" }}
                defaultValue={null}
              />
            </Form.Item>
          </Col>
        </Row>
        <Card className="mb-4">
          <h4>Client Information</h4>
          <p>Client Name: {clientDetails?.clientName} </p>
          <p>Phone: {clientDetails?.patient_phone}</p>
          <p>DOB: <FormatDate date={clientDetails?.date_of_birth}/> </p>

          <Form.Item name={'prior_auth_number'} label='Prior Authorization Number'>
            <Input placeholder="Prior Authorization Number" style={{width:'400px'}}/>
          </Form.Item>
        </Card>

        <Card title="Add Diagnosis Codes">
          <div className="flex justify-between items-center">
            <Form.Item label="Search Patient Diagnosis" name="diagnosisCode">
              <AutoComplete
                name="diagnosisCode"
                onSearch={handleSearchDiagnosis}
                options={options}
                allowClear
                onSelect={onSelect}
                placeholder="Search Diagnosis Code"
                maxLength={100}
                style={{ minWidth: "400px" }}
                defaultValue={null}
              />
            </Form.Item>
          </div>
          <Table
            size="small"
            columns={diagnosisColumn}
            dataSource={diagnosisDetails}
            pagination={false}
          />
        </Card>

        <Card title="Add Services" className="mt-4">
          <Row gutter={[16, 16]}>
            <Col xs={24} md={8} lg={8}>
              <Form.Item label="Facility Code" name={"pos"} rules={[rules]}
                initialValue={facilty_code && facilty_code[0]}
              >
              <Select
                placeholder="Select a facility"
                showSearch
                onSearch={handleSearchFacilityCodes}
                filterOption={false}
              >
                {memoizedFilteredOptions?.map((option) => (
                  <Option key={option.code} value={option.code}>
                    <span title={option.description}>{option.code} - {option.service_name}</span>
                  </Option>
                ))}
              </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={6} lg={6}>
              <Form.Item
                label="Search Procedure Code"
                name={"procedureCodes"}
                rules={[rules]}
              >
                <AutoComplete
                  onSearch={handleSearchCPTCodes}
                  options={CPTCodesOptions}
                  onChange={handleCPTCode}
                  placeholder="Search CPT Codes"
                  maxLength={100}
                  allowClear
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={10} lg={10}>
              <Form.Item label="DX" name={"dx_value"} rules={[rules]}>
                <Select
                  allowClear
                  mode="multiple"
                  placeholder="Select DX"
                  onChange={handleSelectDX}
                  value={selectedDiagnosisIds}
                >
                  {diagnosisDetails &&
                    diagnosisDetails.map((item) => (
                      <Select.Option key={item.dx_link} value={item.dx_link}>
                        {item.dx_link} - {item.dx}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={12} md={6} lg={6}>
              <Form.Item
                label="Search Service Type"
                name={"service_type"}
                rules={[rules]}
              >
                <AutoComplete
                  onSearch={handleSearchServices}
                  options={serviceTypeOptions}
                  //   onChange={handleCPTCode}
                  placeholder="Search Service Type"
                  maxLength={100}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Form.Item label="Modifier" name={"modifier"} 
                tooltip='Seprate each Modifier by pressing Return on your keyboard'
              >
                <Select
                  mode="tags"
                  style={{
                    width: "100%",
                  }}
                  onChange={handleChange}
                  tokenSeparators={[","]}
                />
              </Form.Item>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Form.Item label="Unit" name={"unit"} rules={[rules, {validator: validateNumber}]}>
                <Input placeholder="Unit" maxLength={10}/>
              </Form.Item>
            </Col>

            <Col xs={12} md={6} lg={6}>
              <Form.Item label="Paid" name={"paid"} rules={[{validator: validatePrice}]}>
                <Input placeholder="Paid" maxLength={15}/>
              </Form.Item>
            </Col>
          </Row>

          <div className="flex justify-end mb-2">
            <Button type="primary" onClick={handleAddService}>
              Add Service
            </Button>
          </div>
          <Table
            size="small"
            columns={servicesColumn}
            dataSource={services}
            pagination={false}
          />
        </Card>

        <div className="flex justify-between items-center mt-4">
          <Button onClick={onClose}>Cancel</Button>
          <div>
            <Button type="primary" className="ml-2" onClick={handleSuperbill} loading={loading}>
              Create Superbill
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default SuperbillDrawer;
