import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Table, Button, Dropdown, Tooltip } from "antd";
import { doPatch, doDelete } from "../../../API/apis";
import { EllipsisOutlined, PlusOutlined } from "@ant-design/icons";
import { useStateGlobalContext } from "../../../contexts/GlobalContext";
import { CSVLink } from "react-csv";
import { SlRefresh } from "react-icons/sl";
import Notification from "../../../components/Notification/Notification";
import FormatDateTime from "../../../components/FormatDate/FormatDateTime";
import showConfirmModal from "../../../components/ModalConfirm/ModalConfirm";
import { taskActions } from "../../../components/GlobalComponents/GlobalComponents";
import TasksModal from "./components/TasksModal";


const Tasks = () => {
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [taskModal, setTaskModal] = useState(false);

  const { providers, profile, tasks, getTasks } = useStateGlobalContext();

  // Memoize columns to prevent re-creation on each render
  const coloumn = useMemo(() => [
    {
      title: "#",
      dataIndex: "serial_number",
      width: "5%",
      key: "serial_number",
      render: (text, record, index) => index + 1,
      align: "center",
    },
    {
      key: 1,
      title: "Assigned By",
      ellipsis: true,
      dataIndex: "assigned_by",
      filters: providers?.map(provider => ({
        text: provider.name,
        value: `assignedBy_${provider.name}`,
      })),
      onFilter: (value, record) =>
        record?.assigned_by?.toLowerCase().includes(value.split("_")[1].toLowerCase()),
      render: text => <span>{text}</span>,
    },
    {
      key: 2,
      title: "Detail",
      ellipsis: true,
      dataIndex: "detail",
    },
    {
      key: 3,
      title: "Assigned To",
      ellipsis: true,
      width: "10%",
      dataIndex: "assigned_to",
      filters: providers?.map(provider => ({
        text: provider.name,
        value: `assignedTo_${provider.name}`,
      })),
      onFilter: (value, record) =>
        record?.assigned_to?.toLowerCase().includes(value.split("_")[1].toLowerCase()),
      render: text => <span>{text}</span>,
    },
    {
      key: 4,
      title: "Assigned Date",
      ellipsis: true,
      align: "center",
      dataIndex: "entered_date",
      sorter: (a, b) => new Date(a.entered_date) - new Date(b.entered_date),
      render: text => (
        <Tooltip title={<FormatDateTime date={text} type="time" />}>
          <span>{text ? <FormatDateTime date={text} type="date" /> : ""}</span>
        </Tooltip>
      ),
    },
    {
      key: 5,
      title: "Status Date",
      ellipsis: true,
      width: "10%",
      align: "center",
      dataIndex: "status_updated_on",
      sorter: (a, b) => new Date(a?.status_updated_on) - new Date(b?.status_updated_on),
      render: text => (
        <Tooltip title={<FormatDateTime date={text} type="time" />}>
          <span>{text ? <FormatDateTime date={text} type="date" /> : ""}</span>
        </Tooltip>
      ),
    },
    {
      key: 6,
      title: "Signed By",
      ellipsis: true,
      align: "center",
      dataIndex: "signed_by",
    },
    {
      key: 7,
      title: "Status",
      ellipsis: true,
      width: "8%",
      align: "center",
      dataIndex: "status",
      render: text => {
        const statusStyles = {
          "In Progress": ["bg-blue-200", "text-blue-500"],
          Completed: ["bg-green-200", "text-green-500"],
          "High Priority": ["bg-red-200", "text-red-500"],
          "To do": ["bg-yellow-200", "text-yellow-500"],
          "Need Help!": ["bg-orange-200", "text-orange-500"],
        };
        const [bgColor, textColor] = statusStyles[text] || ["bg-yellow-200", "text-yellow-500"];
        return <span className={`${bgColor} px-3 py-1 ${textColor} font-semibold text-xs rounded-md`}>{text}</span>;
      },
    },
    {
      key: 8,
      title: "Action",
      align: "center",
      width: "5%",
      render: (text, record) => (
        <Dropdown
          menu={{
            items: taskActions
              .filter(item => 
                !(item.key === 1 && record.status === "Completed") &&
                !(item.key === 6 && record.status === "Completed")
              )
              .map(item => ({
                ...item,
                onClick: () => {
                  if (item.key === 1) handleUpdate(record);
                  else handleStatus(record, item.key);
                },
              })),
          }}
          trigger={["click"]}
        >
          <EllipsisOutlined className="cursor-pointer" />
        </Dropdown>
      ),
    },
  ], [providers]);

  const handleUpdate = useCallback((record) => {
    if (record.status !== "Completed") {
      setEdit(true);
      setSelectedItem(record);
      setTaskModal(true);
    } else {
      Notification.warning("Cannot edit a completed task.");
    }
  }, []);

  const handleStatus = useCallback(async (record, key) => {
    const statusMap = {
      2: "To Do",
      3: "In Progress",
      4: "Need Help!",
      5: "High Priority",
      6: "Completed",
    };
    const data = { status: statusMap[key] };

    try {
      const response = await doPatch(`/tasks/update/${record.id}`, data);
      if (response.status === 200) {
        Notification.success("Status updated Successfully");
        getTasks(false);
      }
    } catch (error) {
      Notification.error("An error occurred while updating status");
    }
  }, [getTasks]);

  const handleCancel = useCallback(() => {
    setEdit(false);
    setTaskModal(false);
  }, []);

  const handleDelete = useCallback(() => {
    showConfirmModal({
      title: "Confirm Delete?",
      content: "Are you sure you want to Remove All Completed Tasks?",
      okText: "Delete",
      okType: "danger",
      onOk: confirmDelete,
    });
  }, []);

  const confirmDelete = useCallback(async () => {
    try {
      const response = await doDelete("/tasks/delete-old-tasks/");
      if (response.status === 200) {
        Notification.success("All Completed Tasks Deleted Successfully");
        getTasks(false);
      }
    } catch (error) {
      Notification.error("Something went wrong");
    }
  }, []);

  useEffect(() => {
    const fetchTasks = async () => {
      setLoading(true);
      await getTasks(false);
      setLoading(false);
    };
    fetchTasks();
  }, []);

  document.title = "Tasks | American EMR";

  const userRole = profile?.user_info[0]?.role;
  return (
    <div className="max-w-[1800px] mx-auto">
      <div className="flex justify-between items-center mb-2">
        <h1 className="font-bold text-lg">Tasks</h1>
        <div>
          <Tooltip title="Refresh" onClick={() => getTasks(false)}>
            <SlRefresh size={16} className="cursor-pointer mr-4" />
          </Tooltip>

          {((profile && userRole === "clinic") ||
            profile?.user_info[0]?.role === "manager") && (
            <Button className="danger__button mr-2" onClick={handleDelete}>
              Remove
            </Button>
          )}
          {tasks && tasks?.length > 0 && (
            <Button className="mr-2">
              <CSVLink data={tasks} filename={"Tasks.csv"} className="csv-link">
                Download Report
              </CSVLink>
            </Button>
          )}
          <Button
            type="primary"
            className="font-bold"
            onClick={() => setTaskModal(true)}
          >
            New Task <PlusOutlined />
          </Button>
        </div>
      </div>
      <Table
        size="small"
        columns={coloumn}
        dataSource={tasks}
        loading={loading}
        pagination={{
          defaultPageSize: 15,
          showSizeChanger: true,
          total: tasks?.length,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
        }}
      />

      <TasksModal
        visible={taskModal}
        onClose={handleCancel}
        edit={edit}
        selectedItem={selectedItem}
      />
    </div>
  );
};

export default Tasks;
