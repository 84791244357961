import React, { useMemo } from "react";
import Notification from "../Notification/Notification";
import { AutoComplete, Radio, Space, Tooltip } from "antd";
import {
  BsBank,
  BsCardChecklist,
  BsCashCoin,
  BsFillCreditCard2BackFill,
} from "react-icons/bs";

const radioOptions = [
  { label: "CARD/POS", icon: <BsFillCreditCard2BackFill />, value: "card" },
  { label: "CASH", icon: <BsCashCoin />, value: "cash" },
  { label: "BANK WIRE", icon: <BsBank />, value: "bank" },
  { label: "CHECK", icon: <BsCardChecklist />, value: "check" },
];

export const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      Notification.success("Copied to clipboard!");
    })
    .catch((err) => {
      console.error("Failed to copy: ", err);
    });
};

export const CopyableText = ({ text, className = "" }) => {
  return (
    <Tooltip title="Click to copy">
      <span
        onClick={() => copyToClipboard(text)}
        className={`${className} cursor-pointer`}
      >
        {text}
      </span>
    </Tooltip>
  );
};

export const RadioGroup = ({ value, onChange, ...rest }) => {
  return (
    <Radio.Group
      value={value}
      onChange={onChange}
      {...rest}
      className="radio-tile-group"
      buttonStyle="solid"
    >
      {radioOptions.map((option, index) => (
        <Radio.Button key={index} value={option.value}>
          <span className="flex flex-row justify-center items-center gap-2">
            {option.icon && <span>{option.icon}</span>}
            <p>{option.label}</p>
          </span>
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

export const SearchProduct = ({ options, onSelect, onSearch }) => {
  return (
    <AutoComplete
      options={options}
      allowClear={true}
      onSelect={onSelect}
      onSearch={onSearch}
      placeholder="Search Products"
    />
  );
};

export const invoiceStatus = [
  { key: 1, label: "Paid" },
  { key: 2, label: "unpaid" },
  { key: 3, label: "Cancelled" },
];

export const encounter_Status = [
  { key: 1, label: "Draft" },
  { key: 2, label: "Signed" },
  { key: 3, label: "Cancelled" },
];

export const actionItems = [
  {
    key: 1,
    label: "View",
  },
  {
    key: 2,
    label: "Edit",
  },
  {
    key: 3,
    label: "Delete",
  },
];

export const taskActions = [
  {
    key: 1,
    label: "Edit",
  },
  {
    key: 2,
    label: "To Do",
  },
  {
    key: 3,
    label: "In Progress",
  },
  {
    key: 4,
    label: "Need Help!",
  },
  {
    key: 5,
    label: "High Priority",
  },
  {
    key: 6,
    label: "Mark as Completed",
  },
];

export const RaceRadioGroup = ({ defaultValue = "notAsked", onChange }) => {
  return (
    <Radio.Group
      className="mb-4"
      defaultValue={defaultValue}
      onChange={onChange}
    >
      <Space direction="vertical">
        <Radio value="americanIndian">American Indian or Alaska Native</Radio>
        <Radio value="asian">Asian</Radio>
        <Radio value="black">Black or African American</Radio>
        <Radio value="nativeHawaiian">
          Native Hawaiian or Other Pacific Islander
        </Radio>
        <Radio value="white">White</Radio>
        <Radio value="other">Other</Radio>
        <Radio value="decline">Decline to specify</Radio>
        <Radio value="notAsked">Provider didn't ask</Radio>
      </Space>
    </Radio.Group>
  );
};

export const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    ["bold", "italic"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ align: [] }],
    ["link", "image"],
    ["clean"],
  ],
};

export const generalNotesItems = [
  { key: 1, label: "View" },
  { key: 2, label: "Edit" },
  { key: 3, label: "Print" },
  { key: 5, label: "Delete" },
  { key: 6, label: "Cancel General Notes" },
];

export const waitingRoomStatus = [
  {
    key: 1,
    label: "In Waiting Room",
  },
  {
    key: 2,
    label: "In Exam Room",
  },
  {
    key: 3,
    label: "Ready for Provider",
  },
  {
    key: 4,
    label: "With Provider",
  },
  {
    key: 5,
    label: "Ready for Lab Work",
  },
  {
    key: 6,
    label: "Ready for Imaging",
  },
  {
    key: 7,
    label: "Ready for Check Out",
  },
  {
    key: 8,
    label: "Check Out",
  },
];

// <Option value="Draft">Draft</Option>
// <Option value="Submitted">Submitted</Option>
// <Option value="on Hold">On Hold</Option>
// <Option value="Denied">Denied</Option>
// <Option value="Approved">Approved</Option>
// <Option value="Paid">Paid</Option>
export const insuranceClaimsStatus = [
  {
    key: "Draft",
    label: "Draft",
  },
  { key: "Submitted", label: "Submitted" },
];
