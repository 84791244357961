// export const headers = {
//   "Content-Type": "application/json",
//   Authorization: `Bearer ${localStorage.getItem("token")}`,
// }

import Currency from "./Currency";

export const phoneValidator = (_, value) => {
  if (!value || /^[0-9-+]{5,20}$/.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject("Please enter a valid phone number");
};

export const zipValidator = (_, value) => {
  if (!value || /^[a-zA-Z0-9-\s]+$/.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject("Please enter a valid zip code/Postal code");
};

// export const faxValidator = (_, value) => {
//   if (!value || /^(\+\d{1,3}[- ]?)?\d{8,15}$/.test(value)) {
//     return Promise.resolve();
//   }
//   return Promise.reject("Please enter a valid fax number");
// };

export const validateName = (_, value) => {
  if (value && !/[a-zA-Z\s]+$/.test(value)) {
    return Promise.reject(
      "Name must only contain alphabetic characters and spaces"
    );
  } else {
    return Promise.resolve();
  }
};

export const emailValidator = (_, value, callback) => {
  if (
    value &&
    !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(value)
  ) {
    return Promise.reject("Invalid email address");
  } else {
    return Promise.resolve();
  }
};

export const validateUsername = (_, value) => {
  if (value && !/^[a-z0-9_]{8,20}$/.test(value)) {
    return Promise.reject(
      "Username must be between 8 and 20 characters long, and contain only lowercase letters, numbers, and underscores"
    );
  }
  return Promise.resolve();
};

export const validatePassword = (_, value) => {
  if (value) {
    if (
      !/(?=.*[A-Z])/.test(value) ||
      !/(?=.*\d)/.test(value) ||
      !/(?=.*[!@#$%^&*])/.test(value)
    ) {
      return Promise.reject(
        "Password must contain at least one uppercase letter, one number, and one special character (!@#$%^&*)"
      );
    }
  }
  return Promise.resolve();
};

export const validateNumber = (_, value) => {
  if (value) {
    // Regular expression for an integer
    const integerPattern = /^\d+$/;

    if (!integerPattern.test(value)) {
      return Promise.reject("Please enter a valid integer value (no decimals allowed)");
    }
  }
  return Promise.resolve();
};



export const textValidator = (_, value) => {
  if (value && !/^[a-zA-Z0-9-_/]*$/.test(value)) {
    return Promise.reject(
      "This field can only contain alphanumeric characters, _, -, ., and /"
    );
  }
  return Promise.resolve();
};

export const alphaNumericValidator = (_, value) => {
  if (value && !/[a-zA-Z0-9]+$/.test(value)) {
    return Promise.reject(
      "This field can only contain alphanumeric characters"
    );
  }
  return Promise.resolve();
};

export const alphaNumericSpaceValidator = (_, value) => {
  if (value && !/^[a-zA-Z0-9\s.,]+$/.test(value)) {
    return Promise.reject(
      "This field can only contain alphanumeric characters and spaces"
    );
  }
  return Promise.resolve();
};

export const validateBloodPressure = (_, value) => {
  if (value) {
    if (!/^\d{2,3}\/\d{2,3}$/.test(value)) {
      return Promise.reject(
        "Please enter a valid blood pressure (e.g., 120/80)"
      );
    }
  }
  return Promise.resolve();
};

export const validateBasicVitals = (_, value) => {
  if (value) {
    if (!/^\d{1,3}$/.test(value)) {
      return Promise.reject(`Please enter a valid value`);
    }
  }
  return Promise.resolve();
};

export const validateDecimalNumber = (_, value) => {
  if (value) {
    if (!/^\d{1,7}(\.\d{1,2})?$/.test(value)) {
      return Promise.reject("Please enter a valid value");
    }
  }
  return Promise.resolve();
};

export const isValidCardNumber =(_, value)=> {
  if (value) {
    if (!/^[0-9]+$/.test(value)) {
      return Promise.reject("Please enter a valid Credit / Debit Card");
    }
  }
  return Promise.resolve();
}

export const idValidator = (_, value) => {
  if (value) {
    if (!/^[a-zA-Z0-9\-_./]+$/.test(value)) {
      return Promise.reject("Please enter a valid ID");
    }
  }
  return Promise.resolve();
};

export const validateClinicName = (_, value) => {
  if (value) {
    if (!/^[a-zA-Z0-9\s]+$/.test(value)) {
      return Promise.reject(
        "Name must only contain alphabetic characters and spaces"
      );
    }

    if (value.includes("  ")) {
      return Promise.reject("Name cannot contain two consecutive spaces");
    }

    if (value.endsWith(" ")) {
      return Promise.reject("Name cannot end with a space");
    }
  }
  return Promise.resolve();
};

export const validateServiceName = (_, value) => {
  if (value) {
    // Check for valid characters (alphabetic, numeric, and spaces)
    if (!/^[a-zA-Z0-9\s]+$/.test(value)) {
      return Promise.reject(
        "Name must only contain alphabetic characters, numeric digits, and spaces"
      );
    }

    // Check for consecutive spaces
    if (value.includes("  ")) {
      return Promise.reject("Name cannot contain two consecutive spaces");
    }

    // Check for trailing spaces
    if (value.trim() !== value) {
      return Promise.reject("Name cannot start or end with a space");
    }
  }
  return Promise.resolve();
};



export const NumberFormatter = ({ value, options }) => {
  // const formatter = new Intl.NumberFormat(undefined, options);
  return (
      <Currency value={value}/>
  );
};

// export const NumberFormatter = ({ value, options = {} }) => {
//   const defaultOptions = {
//     style: "currency", 
//     currency: 'usd',  
//     minimumFractionDigits: 2,
//     maximumFractionDigits: 2,
//   };

//   const formatter = new Intl.NumberFormat(undefined, { ...defaultOptions, ...options });
//   return (
//     <span>
//       {formatter.format(value)} 
//     </span>
//   );
// };


export const discountValidator = (_, value) => {
  // Regular expression for a whole number between 0 and 100
  const discountPattern = /^([0-9]|[1-9][0-9]|100)$/;

  if (value && !discountPattern.test(value)) {
    return Promise.reject('Discount must be a whole number between 0 and 100.');
  }

  const numericValue = parseInt(value, 10);

  if (numericValue < 0 || numericValue > 100) {
    return Promise.reject('Discount must be between 0% and 100%.');
  }

  return Promise.resolve();
};





export const getMaskedCardNumber = (cardNumber) => {
  const maskedDigits = cardNumber?.slice(0, -4).replace(/\d/g, "*");
  const visibleDigits = cardNumber?.slice(-4);
  const formattedNumber = maskedDigits?.replace(/(.{4})/g, "$1 ");
  return formattedNumber + visibleDigits;
};

export const getUnMaskSpace =(cardNumber)=>{
  const formattedNumber = cardNumber?.replace(/(.{4})/g, "$1 ");
  return formattedNumber 
}

export const validatePainScale = (_, value) => {
  if (value === undefined || value === null || value === '') {
    return Promise.resolve();
  }
  if (value >= 1 && value <= 10) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('Pain scale must be between 1 and 10'));
};

export const calTotal = (labData) => {
  if (!labData || labData.length === 0) {
    return 0;
  }

  const totalPrice = labData.reduce((total, item) => {
    const itemPrice = parseFloat(item.lab_price) || 0;
    return total + itemPrice;
  }, 0);
  // price = totalPrice.toFixed(2);
  return totalPrice.toFixed(2);
};

export const validatePrice = (_, value) => {
  if (value === undefined || value === null || value === '') {
    return Promise.resolve();
  }
  const priceStr = value.toString();
  const regex = /^(?!0\d)\d+(\.\d{2})?$/;

  if (regex.test(priceStr) && value >= 0) {
    return Promise.resolve();
  }

  let errorMessage = 'Invalid price format.';
  
  if (isNaN(value)) {
    errorMessage = 'Price must be a number.';
  } else if (value < 0) {
    errorMessage = 'Price cannot be negative.';
  } else if (priceStr.includes('.') && priceStr.split('.')[1].length > 2) {
    errorMessage = 'Price cannot have more than two decimal places.';
  }

  return Promise.reject(new Error(errorMessage));
};

export const validateTax = (_, value) => {
  if (value === undefined || value === null || value === '') {
    return Promise.resolve();
  }
  const taxStr = value.toString();
  const regex = /^(?!0\d)\d+(\.\d{2})?$/; // Ensure exactly two decimal places if a decimal is present

  if (regex.test(taxStr) && value >= 0) {
    return Promise.resolve();
  }

  let errorMessage = 'Invalid tax format.';

  if (isNaN(value)) {
    errorMessage = 'Tax must be a number.';
  } else if (value < 0) {
    errorMessage = 'Tax cannot be negative.';
  } else if (taxStr.includes('.') && taxStr.split('.')[1].length !== 2) {
    errorMessage = 'Tax must have exactly two decimal places.';
  }

  return Promise.reject(new Error(errorMessage));
};




export const phoneValidation=(_, value)=> {
  return new Promise((resolve, reject) => {
      const phoneRegex = /^\+?[0-9\s\-()]{7,}$/;
      if(value === undefined){
        resolve()
      }
      else if (phoneRegex.test(value)) {
          resolve("Valid phone number.");
      } else {
          reject("Invalid phone number.");
      }
  });
}

export const faxValidator=(_, value)=> {
  return new Promise((resolve, reject) => {
    if (value === undefined || value === null || value.trim() === '') {
      resolve("Fax number not provided; not required.");
      return;
  }

    const faxRegex = /^\+?[0-9\s\-()]{7,}$/;

    if (faxRegex.test(value)) {
        resolve("Valid fax number.");
    } else {
        reject("Invalid fax number.");
    }
});
}

export const validatePercentage=(_, value)=> {
  return new Promise((resolve, reject) => {
      const parsedValue = parseFloat(value);

      if(value === undefined){
        resolve()
      }      
      else if (isNaN(parsedValue)) {
          reject("Value is not a number.");
      } else if (parsedValue < 0 || parsedValue > 100) {
          reject("Value must be between 0 and 100.");
      } else {
          resolve("Valid percentage.");
      }
  });
}

export const validateQuantity = (_, value) => {
  return new Promise((resolve, reject) => {
    if (!value) {
      return reject("Please enter a valid quantity");
    }
    const numberValue = Number(value);
    if (isNaN(numberValue)) {
      return reject("Quantity must be a number");
    }
    if (!/^\d+$/.test(value)) {
      return reject("Quantity must be a number");
    }
    if (numberValue <= 0) {
      return reject("Quantity must be greater than zero");
    }
    return resolve();
  });
};




export const validateDomain = (_, value) => {
  return new Promise((resolve, reject) => {
    if (!value) {
      // Skip validation if the domain is not provided, since it's not mandatory
      resolve();
      return;
    }

    const domainPattern = /^(?!-)[A-Za-z0-9-]{1,63}(?<!-)\.(?!-)[A-Za-z0-9-]{1,63}(?<!-)(\.[A-Za-z]{2,})+$/;

    // if (domainPattern.test(value)) {
    //   resolve("Valid Website domain.");
    // } else {
    //   reject("Please enter a valid website domain.");
    // }
  });
};


export const discountCalulator = (discount, subtotal) => {
  if (!discount || !subtotal) return 0; 
  const discountAmount = (discount / 100) * subtotal;
  return Number(discountAmount.toFixed(2)); 
};

export const PriceFormatter = (value) => {
  if (!value) return 0; // If no value is provided, return 0
  return parseFloat(value).toFixed(2); // Format the number to 2 decimal places
};
