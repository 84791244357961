import React, { useEffect, useState, useCallback } from "react";
import { Table, Button, Dropdown, Card, Col, Tooltip } from "antd";
import { doPatch } from "../../../../API/apis";
import { EllipsisOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { SlRefresh } from "react-icons/sl";
import TasksModal from "../../Tasks/components/TasksModal";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import Notification from "../../../../components/Notification/Notification";
import { taskActions } from "../../../../components/GlobalComponents/GlobalComponents";

const Tasks = () => {
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [taskModal, setTaskModal] = useState(false);
  const { tasks, getTasks } = useStateGlobalContext();

  const showTaskModal = useCallback(() => {
    setTaskModal(true);
  }, []);

  const handleViewAllTasks = useCallback(() => {
    navigate("/main/tasks");
  }, [navigate]);

  const columns = [
    {
      title: "#",
      dataIndex: "serial_number",
      width: "5%",
      key: "serial_number",
      render: (_, __, index) => index + 1,
      align: "center",
    },
    {
      key: 1,
      title: "Assigned By",
      ellipsis: true,
      dataIndex: "assigned_by",
    },
    {
      key: 2,
      title: "Detail",
      ellipsis: true,
      dataIndex: "detail",
    },
    {
      key: 3,
      title: "Assigned To",
      ellipsis: true,
      dataIndex: "assigned_to",
    },
    {
      key: 7,
      title: "Status",
      ellipsis: true,
      align: "center",
      dataIndex: "status",
      render: (status) => {
        const statusMap = {
          "In Progress": { bgColor: "bg-blue-200", textColor: "text-blue-500" },
          Completed: { bgColor: "bg-green-200", textColor: "text-green-500" },
          "High Priority": { bgColor: "bg-red-200", textColor: "text-red-500" },
          "To do": { bgColor: "bg-yellow-200", textColor: "text-yellow-500" },
          "Need Help!": { bgColor: "bg-orange-200", textColor: "text-orange-500" },
        };

        const { bgColor = "bg-yellow-200", textColor = "text-yellow-500" } = statusMap[status] || {};

        return (
          <span
            className={`${bgColor} px-3 py-1 ${textColor} font-semibold text-xs rounded-md`}
          >
            {status}
          </span>
        );
      },
    },
    {
      key: 8,
      title: "Action",
      align: "center",
      render: (_, record) => (
        <Dropdown
          menu={{
            items: taskActions
              .filter(
                (item) =>
                  !(item.key === 1 && record.status === "Completed") &&
                  !(item.key === 6 && record.status === "Completed")
              )
              .map((item) => ({
                ...item,
                onClick: () => {
                  if (item.key === 1) handleUpdate(record);
                  else handleStatus(record, item.key);
                },
              })),
          }}
          trigger={["click"]}
        >
          <EllipsisOutlined className="cursor-pointer" />
        </Dropdown>
      ),
    },
  ];

  const handleUpdate = useCallback(
    (record) => {
      if (record.status !== "Completed") {
        setEdit(true);
        setSelectedItem(record);
        setTaskModal(true);
      } else {
        Notification.warning("Cannot edit a completed task.");
      }
    },
    []
  );

  const handleStatus = useCallback(async (record, key) => {
    const statusMap = {
      2: "To Do",
      3: "In Progress",
      4: "Need Help!",
      5: "High Priority",
      6: "Completed",
    };

    const status = statusMap[key] || "To Do";
    try {
      const response = await doPatch(`/tasks/update/${record.id}`, { status });
      if (response.status === 200) {
        Notification.success("Status updated successfully");
        getTasks(true);
      }
    } catch (error) {
      Notification.error("An error occurred while updating status");
    }
  }, [getTasks]);

  const handleCancel = useCallback(() => {
    setEdit(false);
    setTaskModal(false);
  }, []);

  useEffect(() => {
    const fetchTasks = async () => {
      setLoading(true);
      await getTasks(true);
      setLoading(false);
    };
    fetchTasks();
  }, []);

  return (
    <Col xl={12}>
      <Card className="mt-4 shadow-sm">
        <div className="flex justify-between items-center mb-4">
          <h1 className="font-bold text-lg">Tasks</h1>
          <div>
            <Tooltip title="Refresh" onClick={() => getTasks(true)}>
              <SlRefresh size={16} className="cursor-pointer" />
            </Tooltip>
            <Button className="ml-4 mr-2" onClick={handleViewAllTasks}>
              View All
            </Button>
            <Button onClick={showTaskModal} type="primary">
              New Task <PlusOutlined />
            </Button>
          </div>
        </div>
        <Table
          size="small"
          columns={columns}
          dataSource={tasks}
          scroll={{ y: 200 }}
          loading={loading}
          pagination={false}
        />
        <TasksModal visible={taskModal} onClose={handleCancel} edit={selectedItem} check={true} />
      </Card>
    </Col>
  );
};

export default Tasks;
