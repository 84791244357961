import React, { useState } from "react";
import { Button, Modal, Form, Input, AutoComplete} from "antd";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import { doGet, doPost } from "../../../../API/apis";
import { useParams } from "react-router-dom";
import Notification from "../../../../components/Notification/Notification";
import BasicButton from "../../../../components/BasicButton/BasicButton";

function BuyService({ visible, onClose, fullName }) {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [options, setOptions] = useState([]); 
  const [selectedService, setSelectedService] = useState({ 
    service_type : '',
    service_group:'',
    service_price: ''
  });
  const { getInvoices, submitLoading, setSubmitLoading} = useStateGlobalContext();

  const handleSearch = async (value) => {
    setOptions([]);

    if (value.length > 2) {
      try {
        const response = await doGet(`/services/search-services/?q=${value}`);
        if (response.status === 200) {
          const filteredOptions = response.data?.map((service) => ({
            id: service?.id,
            value: service?.service_names,
            price: service?.price,
            service_type : service?.service_type,
            service_group: service?.service_group_name,
            label: (
              <div key={id}>
              <div className="flex justify-between">
                <p><span className="font-medium">Name: </span> {service?.service_names}</p>
                <p><span className="font-medium">Type: </span> {service?.service_type}</p>
              </div>
              <div className="flex justify-between">
                <p><span className="font-medium">Group: </span> {service?.service_group_name}</p>
                <p><span className="font-medium">Price: </span> {service?.price}</p>
              </div>
            </div>
            ),
          }));
          setOptions(filteredOptions);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const onSelect = (value, option) => {
    const selectedService = options.find((item) => item.value === value);
    if (selectedService) {
      setSelectedService({
        service_group: selectedService.service_group,
        service_type: selectedService.service_type,
        service_price: selectedService.price
      });
    }
  };


  const handleBuyService = async (values) => {
    setSubmitLoading(true)
    const data = {
      patient_name: fullName,
      service_name: values.service,
      service_group: selectedService.service_group,
      service_type: selectedService.service_type,
      visit_reason: values.reason_for_visit,
      price: selectedService.service_price,

    };
    try {
      const response = await doPost(`/invoice/invoice-add/${id}/`, data);
      if (response.status === 201) {
        Notification.success("Invoice Created Successfully");
        getInvoices(id);
        handleCancel();
      }
    } catch (error) {
      Notification.warning("Something Went Wrong");
    } finally{
      setSubmitLoading(false)
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      title="Buy Service"
      open={visible}
      footer={null}
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">Close</Button>
      }
      onCancel={handleCancel}
    >
      <Form layout="vertical" form={form} onFinish={handleBuyService}>
        <Form.Item
          label="Search Service"
          tooltip="Name, Type, Group, Price"
          name="service"
          rules={[
            {
              required: true,
              message: "Please Enter Service Title",
            },
          ]}
        >
          <AutoComplete
            showSearch
            options={options}
            allowClear={true}
            onSearch={handleSearch}
            onSelect={onSelect}
            placeholder="Search Service"
          />
        </Form.Item>
        <Form.Item label="Reason for Visit" name="reason_for_visit"
         rules={[{
          required: true,
          message: "Please Enter Reason for Visit"
         }]}
        >
          <Input.TextArea
            placeholder="Reason For Visit"
            maxLength={300}
            showCount
          />
        </Form.Item>
        <div className="flex justify-end">
          <Button onClick={handleCancel} className="mr-2">
            Cancel
          </Button>
          <BasicButton loading={submitLoading} title={"Add to Invoice"} saving={true}/>
          {/* <Button type="primary" htmlType="submit">
            Add to Invoice
          </Button> */}
        </div>
      </Form>
    </Modal>
  );
}

export default BuyService;
