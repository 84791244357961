import { Drawer, Checkbox, Button, Card } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useStateGlobalContext } from "../../contexts/GlobalContext";
import { useParams } from "react-router-dom";
import "./facesheet.css";
import FaceSheetHeader from "./FaceSheetHeader";
import { useReactToPrint } from "react-to-print";
import FormatDateTime from "../FormatDate/FormatDateTime";
import FormatDate from "../FormatDate/FormatDate";
import LabelDisplay from "../LabelDisplay/LabelDisplay";

const FaceSheet = ({ visible, onClose }) => {
  const { id } = useParams();
  const contentRef = useRef(null);
  const [selectAll, setSelectAll] = useState(false);
  const [checkedItems, setCheckedItems] = useState({
    allergies: false,
    familyHistory: false,
    vaccinationHistory: false,
    socialHistory: false,
    patientDiagnosis: false,
    surgicalHistory: false,
    allMedications: false,
    pastMedicalHistory: false,
    patientGoals: false,
    primaryInsurance: false,
    secondaryInsurance: false
  });

  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    setCheckedItems({
      allergies: isChecked,
      familyHistory: isChecked,
      vaccinationHistory: isChecked,
      socialHistory: isChecked,
      patientDiagnosis: isChecked,
      surgicalHistory: isChecked,
      allMedications: isChecked,
      pastMedicalHistory: isChecked,
      patientGoals: isChecked,
      primaryInsurance: isChecked,
      secondaryInsurance: isChecked,
    });
  };

  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
  });

  const {
    patientAllergies,
    getAllergies,
    familyHistory,
    getFamilyHistory,
    vaccinationHistory,
    getVaccinationHistory,
    patientDiagnosis,
    getPatientICD10codes,
    socialHistory,
    getSocialHistory,
    surgicalHistory,
    getPatientSurgicalHistory,
    allMedications,
    getAllMedications,
    pastMedicalHistory,
    getPastMedicalHistory,
    patientGoals,
    getPatientGoals,
    insuranceData,
    getInsuranceData,
    secondaryInsurance,
    getSecondaryInsurance
  } = useStateGlobalContext();

  const handleCheckboxChange = (e) => {
    setCheckedItems({
      ...checkedItems,
      [e.target.name]: e.target.checked,
    });
  };

  const getSocialHistoryType = (item) => {
    if (item.tobacco) return "Tobacco";
    if (item.alcohol) return "Alcohol";
    if (item.drugs) return "Drugs";
    if (item.other) return "Other";
    return "Unknown";
  };


  useEffect(() => {
    const fetchData = async () => {
      if (checkedItems.allergies) await getAllergies(id);
      if (checkedItems.familyHistory) await getFamilyHistory(id);
      if (checkedItems.vaccinationHistory) await getVaccinationHistory(id);
      if (checkedItems.socialHistory) await getSocialHistory(id);
      if (checkedItems.patientDiagnosis) await getPatientICD10codes(id);
      if (checkedItems.surgicalHistory) await getPatientSurgicalHistory(id);
      if (checkedItems.allMedications) await getAllMedications(id);
      if (checkedItems.pastMedicalHistory) await getPastMedicalHistory(id);
      if (checkedItems.patientGoals) await getPatientGoals(id);
      if(checkedItems.primaryInsurance) await getInsuranceData(id,"primary")
      if(checkedItems.secondaryInsurance) await getSecondaryInsurance(id, "secondary")
    };

    fetchData();
  }, [
    checkedItems.allergies,
    checkedItems.familyHistory,
    checkedItems.socialHistory,
    checkedItems.patientDiagnosis,
    checkedItems.vaccinationHistory,
    checkedItems.surgicalHistory,
    checkedItems.allMedications,
    checkedItems.pastMedicalHistory,
    checkedItems.patientGoals,
    checkedItems.primaryInsurance,
    checkedItems.secondaryInsurance
  ]);

  return (
    <Drawer
      open={visible}
      onClose={onClose}
      title={"Face Sheet"}
      placement="bottom"
      height="100vh"
      headerStyle={{
        position: "fixed",
        backgroundColor: "white",
        width: "100%",
        top: 0,
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
        zIndex:999
      }}
      className="prescription-drawer"
    >
      <div className="prescription-pres-print max-w-[900px]">
        <div className="prescription-container">
          <h2 className="text-center">Select to Print</h2>
          <p className=" text-gray-400 mt-4 mb-4">Checked values will be printed on the patient's report</p>
          <div className="checkbox-group">
          <Checkbox
              style={{marginBottom: '1.5rem'}}
              name="selectAll"
              checked={selectAll}
              onChange={handleSelectAllChange}
            >
              <strong>Select All</strong>
            </Checkbox>
          <Checkbox
              name="primaryInsurance"
              checked={checkedItems.primaryInsurance}
              onChange={handleCheckboxChange}
            >
              Primary Insurance 
            </Checkbox>

            <Checkbox
              name="secondaryInsurance"
              checked={checkedItems.secondaryInsurance}
              onChange={handleCheckboxChange}
            >
              Secondary Insurance
            </Checkbox>

            <Checkbox
              name="allergies"
              checked={checkedItems.allergies}
              onChange={handleCheckboxChange}
            >
              Allergies
            </Checkbox>

            <Checkbox
              name="patientDiagnosis"
              checked={checkedItems.patientDiagnosis}
              onChange={handleCheckboxChange}
            >
              Patient Diagnosis
            </Checkbox>

            <Checkbox
              name="familyHistory"
              checked={checkedItems.familyHistory}
              onChange={handleCheckboxChange}
            >
              Family History
            </Checkbox>

            <Checkbox
              name="allMedications"
              checked={checkedItems.allMedications}
              onChange={handleCheckboxChange}
            >
              All Medications
            </Checkbox>

            <Checkbox
              name="pastMedicalHistory"
              checked={checkedItems.pastMedicalHistory}
              onChange={handleCheckboxChange}
            >
              Past Medical History
            </Checkbox>

            <Checkbox
              name="surgicalHistory"
              checked={checkedItems.surgicalHistory}
              onChange={handleCheckboxChange}
            >
              Past Surgical History
            </Checkbox>

            <Checkbox
              name="socialHistory"
              checked={checkedItems.socialHistory}
              onChange={handleCheckboxChange}
            >
              Social History
            </Checkbox>

            <Checkbox
              name="vaccinationHistory"
              checked={checkedItems.vaccinationHistory}
              onChange={handleCheckboxChange}
            >
              Vaccination History
            </Checkbox>

            <Checkbox
              name="patientGoals"
              checked={checkedItems.patientGoals}
              onChange={handleCheckboxChange}
            >
              Patient Goals
            </Checkbox>

            
 
          </div>
          <Button type="primary" onClick={handlePrint}>Print</Button>
          <div className="print-pres-content" ref={contentRef}>
          <FaceSheetHeader primaryInsurance={checkedItems.primaryInsurance && insuranceData} secondaryInsurance={checkedItems.secondaryInsurance && secondaryInsurance}/>
          <div className="data-section">
            {checkedItems.allergies && (
              <Card className="facesheet-card">
                <h4 className="text-center mb-4">Allergies</h4>
                {patientAllergies && patientAllergies.length > 0 ? (
                  patientAllergies.map((item) => (
                    <div  key={item.id}>
                      <p>
                        <span>Allergy:</span> {item.allergy_value}
                      </p>
                      <p>
                        <span>Type:</span>{" "}
                        {item.drug
                          ? "Drug"
                          : item.food
                          ? "Food"
                          : item.environment
                          ? "Environment"
                          : "Other"}
                      </p>
                      <p>
                        <span>Reaction:</span> {item.description}
                      </p>
                      <p>
                        <span>Start Date:</span>{" "}
                        <FormatDateTime date={item.start_date} type="date"/>
                      </p>
                      <p>
                        <span>End Date:</span>{" "}
                        <FormatDateTime date={item.end_date} type="date"/>
                      </p>
                      <p>
                        <span>Added by:</span> {item.added_by}
                      </p>
                    </div>
                  ))
                ) : (
                  <p className="no-data">No allergies data available.</p>
                )}
              </Card>
            )}

            {checkedItems.patientDiagnosis && (
              <Card className="mb-4 mt-4 facesheet-card">
                <h4 className="text-center mb-4">Patient Diagnosis</h4>
                {patientDiagnosis && patientDiagnosis?.length > 0 ? (
                  patientDiagnosis?.map((item) => (
                    <div className="p-4"  key={item.id}>
                      <p>
                        <span>Diagnosis Date: </span>
                        <FormatDate date={item.date}/>
                      </p>
                      <p>
                        <span>Code:</span> {item.code}
                      </p>
                      <p>
                        <span>Description:</span> {item.description}
                      </p>
                      <LabelDisplay title="Date Added" description={<FormatDateTime date={item?.date_time} type="datetime"/>}/>
                      <p>
                        <span>Added by:</span> {item.added_by}
                      </p>
                    </div>
                  ))
                ) : (
                  <p className="no-data">No diagnosis data available.</p>
                )}
              </Card>
            )}

            {checkedItems?.familyHistory && (
              <Card className="mb-4 mt-4 facesheet-card">
                <h4 className="text-center mb-4">Family History</h4>
                {familyHistory && familyHistory?.length > 0 ? (
                  familyHistory?.map((item) => (
                    <div className="p-4"  key={item.id}>
                      <p>
                        <span>Family History:</span> {item.family_history}
                      </p>
                      <p>
                        <span>Date Added: </span>
                        <FormatDateTime date={item.date_time} type="datetime"/>
                      </p>
                      <p>
                        <span>Added by:</span> {item.added_by}
                      </p>
                    </div>
                  ))
                ) : (
                  <p className="no-data">No family history data available.</p>
                )}
              </Card>
            )}

            {checkedItems.allMedications && (
              <Card className="mb-4 mt-4 facesheet-card">
                <h4 className="text-center mb-4">All Medications</h4>
                {allMedications && allMedications?.length > 0 ? (
                  allMedications?.map((item) => (
                    <div className="p-4" key={item.id}>
                      <p>
                        <span>Medicine Name:</span> {item.medicine_name}
                      </p>
                      <p>
                        <span>Start Date: </span>
                        {item.start_date  && <FormatDate date={item.start_date}/>}
                      </p>
                      <p>
                        <span>End Date: </span>
                        {item.start_end  && <FormatDate date={item.end_date}/>}
                      </p>
                      <p>
                        <span>Notes:</span> {item.notes}
                      </p>
                      <p>
                        <span>Date Added: </span>
                        {item.date_time  && <FormatDateTime date={item.date_time} type="datetime"/>}
                      </p>
                      <p>
                        <span>Added by:</span> {item.added_by}
                      </p>
                    </div>
                  ))
                ) : (
                  <p className="no-data">No medications data available.</p>
                )}
              </Card>
            )}

            {checkedItems.pastMedicalHistory && (
              <Card className="mb-4 mt-4 facesheet-card">
                <h4 className="text-center mb-4">Past Medical History</h4>
                {pastMedicalHistory && pastMedicalHistory.length > 0 ? (
                  pastMedicalHistory.map((item) => (
                    <div className="p-4" key={item.id}>
                      <LabelDisplay title="Past Medical History Date" description={item?.medical_history_date}/>
                      <p>
                        <span>History:</span> {item.past_medical_history}
                      </p>
                      <p>
                        <span>Date Added: </span>
                        {item?.date_time  && <FormatDateTime date={item.date_time} type="datetime"/>}
                      </p>
                      <p>
                        <span>Added by:</span> {item.added_by}
                      </p>
                    </div>
                  ))
                ) : (
                  <p className="no-data">
                    No past medical history data available.
                  </p>
                )}
              </Card>
            )}

            {checkedItems.surgicalHistory && (
              <Card className="mb-4 mt-4 facesheet-card">
                <h4 className="text-center mb-4">Past Surgical History</h4>
                {surgicalHistory && surgicalHistory.length > 0 ? (
                  surgicalHistory.map((item) => (
                    <div className="p-4" key={item.id}>
                      <p>
                        <span>Past Surgical History:</span>{" "}
                        {item.past_surgical_history}
                      </p>
                      <p>
                        <span>Surgery Date: </span>
                        {item?.surgery_date && <FormatDate date={item.surgery_date}/>}
                      </p>
                      <p>
                        <span>Date Added: </span>
                        {item?.date_time  && <FormatDateTime date={item.date_time} type="datetime"/>}
                      </p>
                      <p>
                        <span>Added by:</span> {item.added_by}
                      </p>
                    </div>
                  ))
                ) : (
                  <p className="no-data">No surgical history data available.</p>
                )}
              </Card>
            )}

            {checkedItems.socialHistory && (
              <Card className="mb-4 mt-4 facesheet-card">
                <h4 className="text-center mb-4">Social History</h4>
                {socialHistory && socialHistory?.length > 0 ? (
                  socialHistory?.map((item) => (
                    <div className="p-4" key={item.id}>
                      <p>
                        <span>Social History:</span>{" "}
                        {getSocialHistoryType(item)}
                      </p>
                      <p>
                        <span>Description:</span> {item.social_history}
                      </p>
                      <p>
                        <span>Start Date: </span>
                        {item?.start_date  && <FormatDate date={item.start_date}/>}
                      </p>
                      <p>
                        <span>End Date: </span>
                        {item?.end_date  && <FormatDate date={item.end_date}/>}
                      </p>
                      <LabelDisplay title="Date Added" description={<FormatDateTime date={item?.date_time} type="datetime"/>}/>
                      <p>
                        <span>Added by:</span> {item.added_by}
                      </p>
                    </div>
                  ))
                ) : (
                  <p className="no-data">No social history data available.</p>
                )}
              </Card>
            )}

            {checkedItems.vaccinationHistory && (
              <Card className="mb-4 mt-4 facesheet-card">
                <h4 className="text-center mb-4">Vaccination History</h4>
                {vaccinationHistory && vaccinationHistory?.length > 0 ? (
                  vaccinationHistory?.map((item) => (
                    <div className="p-4" key={item.id}>
                      <p>
                        <span>Vaccination Date: </span>
                        {item?.vaccination_date  && <FormatDate date={item.vaccination_date}/>}
                      </p>
                      <p>
                        <span>Vaccination:</span> {item.vaccination_history}
                      </p>
                     <LabelDisplay title="Date Added" description={<FormatDateTime date={item?.date_time} type="datetime"/>}/>
                      <p>
                        <span>Added by:</span> {item.added_by}
                      </p>
                    </div>
                  ))
                ) : (
                  <p className="no-data">
                    No vaccination history data available.
                  </p>
                )}
              </Card>
            )}

            {checkedItems?.patientGoals && (
              <Card className="mb-4 mt-4 facesheet-card">
                <h4 className="text-center mb-4">Patient Goals</h4>
                {patientGoals && patientGoals?.length > 0 ? (
                  patientGoals?.map((item) => (
                    <div className="p-4" key={item.id}>
                      <p>
                        <span>Family History:</span> {item.patient_goals}
                      </p>
                      <p>
                        <span>Date: </span>
                        {item?.date_time  && <FormatDateTime date={item.date_time} type="datetime"/>}
                      </p>
                      <p>
                        <span>Added by:</span> {item.added_by}
                      </p>
                    </div>
                  ))
                ) : (
                  <p className="no-data">No patient goals data available.</p>
                )}
              </Card>
            )}
          </div>
          </div> 
        </div>
      </div>
    </Drawer>
  );
};

export default FaceSheet;
