import React, { useEffect, useState } from "react";
import { Modal, Form, Spin, Button } from "antd";
import { useParams } from "react-router-dom";
import { yearCalculator } from "../../../../../utils/ageCalculator";
import MedicalCardsTitle from "../MedicalCardsTitle";
import { PlusOutlined } from "@ant-design/icons";
import CurrentMedicationModal from "../../Prescription/component/CurrentMedicationModal";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import FormatDate from "../../../../../components/FormatDate/FormatDate";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";

function CurrentMedications() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [viewAll, setViewAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentMedicationView, setCurrentMedicationView] = useState(false);
  const [currentMedicationModal, setCurrentMedicationModal] = useState(false);
  const { currentMedication: data, getCurrentMedications } =
    useStateGlobalContext();
  console.log(data, "CURRENT MEDICATIOn");

  const handleCurrentMedicationModal = () => {
    setCurrentMedicationModal(true);
  };

  const hideCurrentMedcicationModal = () => {
    setCurrentMedicationModal(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setCurrentMedicationView(false);
  };

  const handleCurrentMedicationView = (record) => {
    setSelectedItem(record);
    setCurrentMedicationView(true);
  };

  useEffect(() => {
    getCurrentMedications(id);
  }, [id]);

  return (
    <div>
      <div className="shadow-class p-4 border rounded-md  h-[180px]  max-h-[180px]">
        <div className="flex justify-between items-center">
          <MedicalCardsTitle title="All Current Medications" />
          <Button
            size="small"
            type="primary"
            shape="round"
            // onClick={handleViewAll}
            onClick={handleCurrentMedicationModal}
          >
            Add <PlusOutlined />
          </Button>
        </div>

        <CurrentMedicationModal
          open={currentMedicationModal}
          onCancel={hideCurrentMedcicationModal}
        />

        {loading ? (
          <div className="flex justify-center items-center">
            <Spin size="small" />
          </div>
        ) : (
          <div className="max-h-[130px]  overflow-auto mt-1">
            {data
              ?.filter((item) => {
                return !item.end_date || new Date(item.end_date) >= new Date();
              })
              .map((item, idx) => (
                <div key={idx} className="mb-2">
                  <p>
                    <span
                      className="text-[#1890ff] cursor-pointer"
                      onClick={() => handleCurrentMedicationView(item)}
                    >
                      {item?.medicine_name?.slice(0, 50)}{" "}
                      {item?.end_date
                        ? ` (${yearCalculator(
                            item?.start_date,
                            item?.end_date
                          )} years)`
                        : " (present)"}
                    </span>
                    <span
                      className={
                        item?.end_date && new Date(item?.end_date) < new Date()
                          ? "text-white bg-slate-500 text-xs rounded-md px-1 py-[2px] ml-2"
                          : "text-white bg-[#3484F0] text-xs rounded-md px-1 py-[2px] ml-2"
                      }
                    >
                      {item?.end_date && new Date(item?.end_date) < new Date()
                        ? "Inactive"
                        : "Active"}
                    </span>
                  </p>
                  <p>
                    <span className="font-medium">Start Date:</span>
                    <span>
                      {" "}
                      {item?.start_date ? (
                        <FormatDate date={item?.start_date} />
                      ) : (
                        "No Date Entered"
                      )}
                    </span>
                    <span className="font-medium ml-3 ">End Date: </span>
                    <span>
                      {item?.end_date ? (
                        <FormatDate date={item?.end_date} />
                      ) : (
                        "No Date Entered"
                      )}
                    </span>
                  </p>
                </div>
              ))}

            {data?.length === 0 && (
              <li className="flex justify-between items-center">
                <span>No Medications Added</span>
              </li>
            )}
          </div>
        )}
      </div>

      <Modal
        title="Current Medication Details"
        open={currentMedicationView}
        footer={null}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
        onCancel={handleCancel}
      >
        <p>
          <span className="font-medium">Medicine Name: </span>
          <span> {selectedItem?.medicine_name} </span>
        </p>
        <p>
          <span className="font-medium">Start Date: </span>
          <span>
            {selectedItem?.start_date ? (
              <FormatDate date={selectedItem?.start_date} />
            ) : (
              "No Date Entered"
            )}
          </span>
        </p>
        <p>
          <span className="font-medium">End Date: </span>
          <span>
            {selectedItem?.end_date ? (
              <FormatDate date={selectedItem?.end_date} />
            ) : (
              "No Date Entered"
            )}
          </span>
        </p>
        <p>
          <span className="font-medium">Notes: </span>
          <span> {selectedItem?.notes} </span>
        </p>
        <p>
          <span className="font-medium">Added By: </span>
          <span> {selectedItem?.added_by} </span>
        </p>
        <p>
          <span className="font-medium">Added Date: </span>
          <span>
            <FormatDateTime date={selectedItem?.date_time} type="datetime" />
          </span>
        </p>
      </Modal>

      <Modal
        title="All Medications"
        open={viewAll}
        footer={null}
        maskClosable={false}
        onCancel={() => setViewAll(false)}
      >
        {!loading &&
          data &&
          data?.map((item, index) => (
            <div key={index}>
              <p>
                <span
                  className="text-[#1890ff] cursor-pointer"
                  onClick={() => handleCurrentMedicationView(item)}
                >
                  {item?.medicine_name}{" "}
                  {item?.end_date
                    ? ` (${yearCalculator(
                        item?.start_date,
                        item?.end_date
                      )} years)`
                    : " (present)"}
                </span>
                <span
                  className={
                    item?.end_date && new Date(item?.end_date) < new Date()
                      ? "text-white bg-slate-500 text-xs rounded-md px-1 py-[2px] ml-2"
                      : "text-white bg-[#3484F0] text-xs rounded-md px-1 py-[2px] ml-2"
                  }
                >
                  {item?.end_date && new Date(item?.end_date) < new Date()
                    ? "Inactive"
                    : "Active"}
                </span>
              </p>
              <p>
                <span className="font-medium">Start Date: </span>
                <span>
                  {" "}
                  <FormatDate date={item?.start_date} />
                </span>
                <span className="font-medium ml-3 ">End Date: </span>
                <span>
                  {item?.end_date ? (
                    <FormatDate date={item?.end_date} />
                  ) : (
                    "No Date Entered"
                  )}
                </span>
              </p>
            </div>
          ))}
        {data?.length === 0 && (
          <li className="flex justify-between items-center">
            <span>No Medications Added</span>
          </li>
        )}
      </Modal>
    </div>
  );
}

export default CurrentMedications;
